<template lang="">
  <div class="xlb_header" v-if="isLogin">
    <div class="logo">
      <img src="../../../assets/logo.png" alt="" />
      <span>小萝卜社群后台</span>
    </div>
    <div class="xlb_header_con">
      <div class="swich_shop">
        <el-dropdown @command="chooseShop">
          <div class="name">
            {{ currentShopName }}
            <i class="el-icon-caret-bottom"></i>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="item in orgList" :command="item.orgId">{{
                item.name
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="login_info">
        <div class="name" @click="handleLogout">欢迎 {{ name }}，点此登出</div>
        <!--el-dropdown @command="handleLogout">
          <div class="name">{{ name }}</div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown-->
      </div>
    </div>
  </div>
</template>
<script>
import cloudbase from "@cloudbase/js-sdk";
const AV = require("leancloud-storage");
export default {
  data() {
    return {
      currentShopName: "",
      orgList: [],
      name: "",
      isLogin:false
    };
  },
  mounted() {
    console.log("header mounted....");
    if (localStorage.getItem("userInfo")) {
      this.isLogin = true
      this.name = JSON.parse(localStorage.getItem("userInfo")).nickName;
      this.orgList = JSON.parse(localStorage.getItem("orgList"));
      this.currentShopName = localStorage.getItem("currentOrgName")
        ? localStorage.getItem("currentOrgName")
        : this.orgList[0].name;
      console.log("this.orgList[0].orgId", this.orgList[0].orgId);
      this.bus.$emit("currentOrgId", this.orgList[0].orgId);
    } else {
      console.log("未登录");
      this.$router.push({ path: "/login" });
    }
  },
  methods: {
    chooseShop(value) {
      //设置当前的orgId为选中的orgId。 为避免当前页面内容不刷新造成误解，可以弹出提示，询问切换成功后将重新跳转至首页
      //用户点击确认则重新跳转到home，同时刷新左侧菜单
      this.$confirm("切换发起方会跳转至首页", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.orgList.forEach((element) => {
            if (value == element.orgId) {
              this.currentShopName = element.name;
              this.setSessionItem("currentOrgId", value);
              localStorage.setItem("currentOrgName", element.name);
              localStorage.setItem("currentOrgType", element.type);
            }
          });
        })
        .catch(() => {
          console.log("取消切换");
        });
    },
    handleLogout() {
      console.log("222222222222");
      this.$confirm("确认登出系统？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const app = cloudbase.init({
          env: process.env.VUE_APP_API_BASE_ENV,
        });
        let auth = app.auth({ persistence: "local" });
        let provider = auth.weixinAuthProvider({
          appid: process.env.VUE_APP_API_APPID,
          scope: "snsapi_login",
        });
        auth.signOut();
        AV.User.logOut();
        localStorage.clear();
        this.$router.push({ path: "/login" });
        window.removeEventListener("setItem", () => {});
      });
    },
  },
};
</script>
<style lang="scss">
.xlb_header {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .logo {
    width: 180px;
    height: 48px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #272a31;
    border-right: 1px solid #000;
    box-sizing: border-box;
    img {
      height: 60%;
      object-fit: cover;
    }
    span {
      font-size: 16px;
      margin-left: 5px;
    }
  }
}
.xlb_header_con {
  width: calc(100% - 180px);
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  box-sizing: border-box;
  .name {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
  }
  .name:hover {
    color: white;
  }
  .swich_shop {
    cursor: pointer;
  }
  .login_info {
    cursor: pointer;
  }
}
</style>
