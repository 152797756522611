import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import cloudbase from "@cloudbase/js-sdk";
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
Vue.use(ElementUI);

const AV = require('leancloud-storage')
Vue.prototype.$AV = AV

AV.init({
            appId:"6IkiQ1QmPKMayoS8DKVi7067-gzGzoHsz",
            appKey:"z2mzXszuCiHLIiru2XNSwAb9",
            serverURLs:'https://api.xiaobaoming.com'
})
//AV.setProduction(0)
Vue.prototype.bus = new Vue()
Vue.prototype.setSessionItem = function (key, newVal) {
    // 创建 StorageEvent 事件
    let newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
        setItem: function (k, val) {
            localStorage.setItem(k, val);

            // 初始化 StorageEvent 事件
            newStorageEvent.initStorageEvent(
                "setItem", // 事件别名
                false,
                false,
                k,
                null,
                val,
                null,
                null
            );
            // 派发事件
            window.dispatchEvent(newStorageEvent);
        },
    };
    return storage.setItem(key, newVal);
};

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')